<script>
import permissionsService from '@/services/permissions.service'
import Permission from '@/data/models/Permission'
import EditLayout from '@/layout/EditLayout'
import indexing from '@/data/enums/seoIndexing.enum'

export default {
  name: 'SinglePermission',
  components: {
    EditLayout
  },
	data() {
		return {
      item: new Permission,
      indexing: indexing,
		}
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    isNew() {
      return this.id === 'new'
    },
  },
	mounted() {
    !this.isNew && this.id && this.get()
	},
	methods: {
    get(type = null) {
      permissionsService.getById({ id: this.id })
      .then(res => {
        this.item = res.data
      })
    },
    create() {
      permissionsService.create({ item: this.item })
        .then(_res => {
          this.$notify({
            message: 'Permission successfully added',
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
          this.$store.dispatch('permissions/getAllPermissions')
          this.$router.push({ name: 'permissions' })
        })
    },
    edit() {
      permissionsService.edit({ item: this.item })
        .then(_res => {
          this.$store.dispatch('permissions/getAllPermissions')
          this.$notify({
            message: 'Permission successfully saved',
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        })
    },
	}
}
</script>

<template>
  <EditLayout 
    :item="item"
    :submit-button-text="isNew ? $t('create') : $t('save')"
    :supports-editor="false"
    @submit="isNew ? create() : edit()"
  />
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>
